import React from 'react'
import Support1 from '../images/Sabean/new test machine.jpg'
import Testing1 from '../images/Sabean/SABEAN Water Meter-2888.webp'
import ImageSlider from '../Components/ImageSlider';
import im1 from '../images/Action/SABEAN Water Meter-1956.webp'
import im2 from '../images/Action/SABEAN Water Meter-2282.webp'
import im3 from '../images/Action/SABEAN Water Meter-2493.webp'
import im4 from '../images/Action/SABEAN Water Meter-2561.webp'
import im5 from '../images/Action/SABEAN Water Meter-2624.webp'
import im6 from '../images/Action/SABEAN Water Meter-2842.webp'
import im7 from '../images/Action/SABEAN Water Meter-4006.webp'
import im8 from '../images/Action/SABEAN Water Meter-4226.webp'
import im9 from '../images/Action/SABEAN Water Meter-4308.webp'


const Service = () => {
    const slides = [
        {
          imageSrc: im1,
        },
        {
          imageSrc: im2,
        },
        {
          imageSrc: im3,
         
        },
        {
          imageSrc: im4,
         
        },
        {
          imageSrc: im5,
         
        },
        {
          imageSrc: im6,
         
        },
        {
          imageSrc: im7,
         
        },
        {
          imageSrc: im8,
         
        },
        {
          imageSrc: im9,
         
        },
      ];


  return (
    <div className='mt-28 md:mx-20'>
        <h3 className="text-5xl font-serif text-center pt-4 mb-6 text-[#0077b6] md:px-8">Services</h3>

        <section className="flex flex-col lg:flex-row-reverse max-h-fit justify-between items-center mb-4 bg-sky-800 text-white z-10 relative gap-8">
            {/* Image Section */}
            <div className="lg:w-2/5  h-full">
            <img
                src={Support1}
                // className=" object-cover w-full h-full"
            />
            </div>
            <div className="lg:w-3/5 py-16 px-6 md:px-20">
            <h2 className="text-3xl font-bold mb-4 border-b-2 border-accent pb-2">
            Calibration and Testing
            </h2>
            <p className="text-lg leading-relaxed mb-8">
            All Sabean water meters undergo rigorous testing and calibration to maintain accuracy and insure compliance with industry standards.
            </p>
            </div>

            
        </section>

        <section className="flex flex-col lg:flex-row-reverse max-h-fit justify-between items-center mb-4 bg-sky-700 text-white z-10 relative gap-8">
            
            <div className="lg:w-3/5 py-16 px-6 md:px-20">
            <h2 className="text-3xl font-bold mb-4 border-b-2 border-accent pb-2">
            Customer Support and Training
            </h2>
            <p className="text-lg leading-relaxed mb-8">
            Our customer support team is ready to assist with any inquiries or technical support needs.
            </p>
            <p className="text-lg leading-relaxed mb-8">
            We also help service providers identify revenue losses caused by aging water meters, enabling timely replacements and efficiency improvements.
            </p>
            </div>
            {/* Image Section */}
            <div className="lg:w-2/5  h-full">
            <img
                src={Testing1}
                // className=" object-cover w-full h-full"
            />
            </div>
            
        </section>

        <h2 className="text-3xl font-semibold text-[#0077b6] text-center mt-6 mb-10">Our Team in Action</h2>
        <div className="w-auto mx-auto mb-4">
            <ImageSlider slides={slides} />
        </div>

    </div>
  )
}

export default Service




