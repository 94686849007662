import React from 'react'
import img from '../images/Sabean/sabean about 2.jpg'
import img2 from '../images/Sabean/sabean about.jpg'
import CounterUp from '../Components/counterUp'
import certificate from '../images/Sabean/Certificate.webp'

const about = () => {
  return (
    <div className='mt-28'>
        <section className="font-serif justify-center items-center md:mx-12 mx-4 pt-4">

        <section className="flex flex-col lg:flex-row-reverse h-auto justify-between items-center mb-4 bg-sky-900 text-white z-10 relative gap-8">
            
            <div className="lg:w-3/5 py-16 px-6 md:px-20">
            <h2 className="text-4xl font-bold mb-4 border-b-2 border-accent pb-2">
            Welcome To Sabean!
            </h2>
            <p className="text-lg leading-relaxed mb-8">
            Today, Sabean draws inspiration from this legacy. We recognize the enduring value of traditional techniques and indigenous materials, while fostering innovation to ensure continued relevance in the modern world. Our company embodies the enduring spirit of Ethiopian creativity, ensuring that this remarkable heritage thrives for generations to come.
            </p>
            <p className='text-lg leading-relaxed mb-8'>
            Sabean extends beyond the role of a water meter manufacturer; it serves as a pathway towards a future with secure water resources. It acknowledges that the absence of accurate, reliable, and durable water meters contributes to the substantial volume of non-revenue water that Ethiopia loses. These meters are not just measuring devices, but also payment gateways that can help address this issue. And increase revenue of Ethiopia from it’s precious resource, Water. 
            </p>
            </div>
            {/* Image Section */}
            <div className="lg:w-2/5  h-auto overflow-hidden">
            <img
                src={img2}
                // className=" object-cover w-full h-full"
            />
            </div>
            
        </section>

        <section className="flex flex-col lg:flex-row-reverse h-auto justify-between items-center mb-4 bg-sky-900 text-white z-10 relative gap-8">
            
            
            <div className="lg:w-3/5 py-20 px-6 md:px-20 bg-white text-black">
                <h2 className="text-4xl font-bold mb-4 border-b-2 border-accent pb-2">
                Mission & Values
                </h2>
                <p className="text-lg leading-relaxed mb-8">
                Our mission is to deliver high-quality water meters that support efficient water management and water conservation in Ethiopia. We value innovation, sustainability, and community impact.
                </p>
            </div>
            <div className="lg:w-3/5 py-16 px-6 md:px-20 ">
                <h2 className="text-4xl font-bold mb-4 border-b-2 border-accent pb-2">
                Company Overview
                </h2>
                <p className="text-lg leading-relaxed mb-8">
                SABEAN Base Metal Engineering Plc was founded in 2016, focusing on the production of Multijet water meters. Our journey from a small startup to a leader in Ethiopia's water sector reflects our commitment to quality, innovation, and sustainability.
                </p>
            </div>
            
        </section>

        <section>
          
            <div className='flex gap-3 md:flex-row flex-col items-center'>
                <div className='md:w-1/3'>
                    <h3 className="text-5xl text-[#0077b6] md:px-8">Our Achievements</h3>
                    <p className='font-serif text-lg text-[#333333] text-justify my-6 md:px-8'>
                    Since our establishment, SABEAN has distributed its products to over 300 towns and cities across the Ethiopia and aims to increase its presence to African cities across the continent.
                    </p>
                </div>
                <div className='md:w-2/3'>
                    <CounterUp />
                </div>
            </div>

        </section>

        <section className="flex flex-col lg:flex-row-reverse h-auto justify-between items-center mb-4 bg-sky-900 text-white z-10 relative gap-8">
            
            <div className="lg:w-3/5 py-16 px-6 md:px-20 h-full">
            <h2 className="text-4xl font-bold mb-4 border-b-2 border-accent pb-2">
            Message from the CEO
            </h2>
            
                    <p className='text-lg leading-relaxed mb-8'>As one of the founders and CEO of Sabean Base Metal Engineering, I am delighted to share our vision for a water-secure future in Ethiopia and beyond. </p>
                    <p className='text-lg leading-relaxed mb-8'>Our journey began with a commitment to address Ethiopia's unique water management challenges through domestic water meter manufacturing. By sourcing 80% of our raw materials locally, and manufacturing major components of the meter in our factory, we successfully reduced reliance on substandard imported water meters.  Ethiopia has always been relying on imported water meters and has often proved costly, unsustainable and unreliable</p>
                    <p className='text-lg leading-relaxed mb-8'>Our MultiJet water meters are more than just tools for measuring water consumption; they are catalysts for positive change! In addition to the transfer of this technology, generating foreign currency and creating job opportunities for young citizens, manufacturing high accuracy water meters play a crucial role in preserving water resources while serving as precise instruments for revenue collection, contributing to the sustainable management of Ethiopia’s clean water supply.</p>
            </div>
            {/* Image Section */}
            <div className="lg:w-2/5  h-full overflow-hidden">
            <img
                src={img}
                className=" object-cover w-full h-full"
            />
            </div>
            
        </section>

        <section className="flex flex-col lg:flex-row-reverse h-auto justify-between items-center mb-4 bg-gray-200 text-black z-10 relative gap-8">
            
            <div className="lg:w-full py-16 px-6 md:px-20 h-full">
            
                    <p className='text-lg leading-relaxed mb-8'>As Ethiopia's urbanization accelerates, the importance of efficient water metering grows. Our high-quality water meters, designed to meet rigorous ISO4064-2021 standards, are essential for both water conservation and revenue generation. During the past couple of years, the trust that we have established with consumers and suppliers is a testament to our dedication to quality and reliability.</p>
                    <p className='text-lg leading-relaxed mb-8'>Our impact extends beyond Ethiopia, contributing to regional and global water security. We recognize that effective water management requires collaboration, and we are open to partnerships that align with our mission. Whether through transfer of technology, creation of job opportunities, import substitution, or environmental stewardship, we are eager to work with like-minded organizations and individuals to make a difference. </p>
                    <p className='text-lg leading-relaxed mb-8'>Together, we can create a sustainable, water-secure future. I invite you to join us on this journey as we continue to innovate, inspire, and impact lives.</p>
                    <p className='text-lg leading-relaxed mb-8'>We Value Every Droplet of Water!</p>
                    <p className='text-lg leading-relaxed mb-8'>Ainishet Asres (Ph.D.) CEO, Sabean Base Metal Engineering PLC Addis Ababa, Ethiopia</p>
            </div>

            <div className="lg:w-2/5 md:ml-10  h-full overflow-hidden">
            <img
                src={certificate}
                className=" object-cover w-full h-full"
            />
            </div>
            
        </section>

        </section>
    </div>
  )
}

export default about