import React, { useState, useEffect } from 'react';

const ImageSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  // Auto change the slide every 5 seconds
  useEffect(() => {
    if (!isPaused) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
      }, 10000); // 5 seconds interval

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
  }, [isPaused, slides.length]);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const togglePause = () => {
    setIsPaused((prevState) => !prevState);
  };

  return (
    <div className="relative w-full overflow-hidden">
      <div className="flex transition-all duration-500 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {slides.map((slide, index) => (
          <div key={index} className="flex-shrink-0 w-full relative" onClick={togglePause}>
            <img src={slide.imageSrc} alt={slide.imageAlt} className="w-full h-96 object-cover rounded-lg" />
            
          </div>
        ))}
      </div>

      {/* Previous Button */}
      <button
        onClick={goToPrev}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white text-3xl bg-[#0077b6] p-2 rounded-full"
      >
        &#10094;
      </button>

      {/* Next Button */}
      <button
        onClick={goToNext}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white text-3xl bg-[#0077b6] p-2 rounded-full"
      >
        &#10095;
      </button>
    </div>
  );
};

export default ImageSlider;
