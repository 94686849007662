import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { AiOutlineDeploymentUnit, AiOutlineRise, AiOutlineSmile } from "react-icons/ai";

const stats = [
  { value: 300000, suffix: "+", label: "Deployments", icon: <AiOutlineDeploymentUnit className="text-5xl text-white" /> },
  { value: 99, suffix: "%", label: "Reduction in commercial losses", icon: <AiOutlineRise className="text-5xl text-white" /> },
  { value: 95, suffix: "%", label: "Customer Satisfaction", icon: <AiOutlineSmile className="text-5xl text-white" /> },
];

export default function CounterUp() {
  const { ref, inView } = useInView({ threshold: 0.3 });

  return (
    <div ref={ref} className="flex flex-wrap justify-center gap-6 p-6 items-center">
      {stats.map((stat, index) => (
        <div key={index} className="w-72 p-6 text-center bg-[rgba(0,119,182,0.5)]  rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
          <div className="flex justify-center mb-4">{stat.icon}</div>
          <h2 className="text-5xl font-bold text-white">
            {inView && <CountUp key={inView} end={stat.value} duration={3} suffix={stat.suffix} />}
          </h2>
          <p className="text-lg text-white mt-2 font-medium">{stat.label}</p>
        </div>
      ))}
    </div>
  );
}
